import React, { useState } from 'react';
import { Button } from 'reactstrap';
import * as Icon from 'react-feather';
import Axios from 'axios';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import './reg2.css'


export default function Reg2() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const[companyName,setCompanyName] = useState('');
    const[industry,setIndustry] = useState('');
    const[companyPhone, setCompanyPhone] = useState('');
    const[companyEmail, setCompanyEmail] = useState('');
    const[country, setCountry] = useState('');
    const[state, setState] = useState('');
    const[password, setPassword] = useState('');
    const[fullname, setFullname] = useState('');
    const[email, setEmail] = useState('');
    const[phone, setPhone] = useState('');
    const[position,setPosition] = useState('');
    const[isLoading, setIsLoading] = useState(false);
    const[isLoading2, setIsloading2] = useState(false);

  
    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [name]: value,
    //   }));
    // };
  
    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission behavior
    
      try {
        setIsloading2(true)
        const response = await fetch('https://server.datageboard.com/api/insertMember', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fullname,
            company: companyName,
            email,
            phone,
            position,
            password,
            isLogged: 'Yes'
          })
        });
    
        if (!response.ok) {
          // Handle error response
          throw new Error('Failed to insert member');
        }
    
       setIsloading2(false)
       const mail2 = email;
       const message = 'Your Data G - Eboard account has been Created. To Activate your account, log on to https://board.datageboard.com . For support, contacts us on support@datageboard.com.';

       // Send email using Fetch
       const emailResponse = await fetch('https://server.datageboard.com/api/send-email', {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({
           recipient: mail2,
           content: message,
           subject: 'Account Activation',
         }),
       });

       if (emailResponse.ok) {
        navigate(`/auth/activation?email=${email}`);
       }




        
      } catch (error) {
        // Handle any error that occurred during the fetch request
        console.error('Error:', error);
        // Optionally, display an error message to the user
      }
    };
  
    const nextStep = () => {
      setIsLoading(true);
      
      // Change button text to 'Submitting'

  
    
      fetch('https://server.datageboard.com/api/insertCompany', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          companyName,
          companyEmail,
          companyPhone,
          industry,
          country,
          state
        })
      })
      .then(response => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Failed to insert company');
        }
        return fetch('https://server.datageboard.com/api/create-company-folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company: companyName
          })
        });
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to create company folder');
        }
        setStep(step + 1);
        setIsLoading(false);
   
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
   
      });
    };
    
  
    const prevStep = () => {
      setStep(step - 1);
    };
  
    // Calculate progress percentage
    const progress = (step / 3) * 100;

  return (
    <div className="multi-step-form">

    <div className="regLeft">
      <div className="leftBox">
        <br />
        <br />
        <br />
        <h2>The Simplest way to manage your board activities</h2>
        <img className='demoImage' src="/assets/images/1.jpg" alt="" />
      
        <div className="pointers">
            <p><Icon.UserCheck size='16px' style={{color:'gray'}}/> Minutes generation</p>
            <p><Icon.Calendar size='16px' style={{color:'gray'}}/> Meeting scheduling $ Invitations</p>
            <p><Icon.FilePlus size='16px' style={{color:'gray'}}/> Files management</p>
            <p><Icon.Clipboard size='16px' style={{color:'gray'}}/> Voting & Decision Making</p>
            <p><Icon.Activity  size='16px' style={{color:'gray'}}/> Projects & Task Tracking</p>
        </div>
       
      </div>
       

    </div>
    <div className="regRight">
      <div className="changeWidth">
    {step === 1 && (
     <div className='regForm'>
      <div className="stepsContainer">
        <div className='cont' >
        <img src="/assets/images/logo.png" className='logo' alt="" />
      
      <hr />
      <h4>Company Details</h4>
      <div className="fields">
        <input type="text" placeholder='Company Name' name="companyName" onChange={(e)=>{setCompanyName(e.target.value)}} />
      </div>
      <div className="fields">
        <select  id="" name="industry" onChange={(e)=>{setIndustry(e.target.value)}} >
          <option>Select Industry</option>
          <option value="Accomodation and Food Services" > Accomodation and Food Services</option>
          <option value="Administration">Administration</option>
          <option value="Agriculture,Forestry,Fishing & Hunting">Agriculture,Forestry,Fishing & Hunting</option>
          <option value="Arts,Entertainment & Recreation">Arts,Entertainment & Recreation</option>
          <option value="Construction">Construction</option>
          <option value="Educational Services">Educational Services</option>
          <option value="Finance and Insurance">Finance and Insurance</option>
          <option value="Healthcare and Social Assistance">Healthcare and social Assistance</option>
          <option value="Information Technonology & Software">Information Technology & Software</option>
          <option value="Manufacturing">Manufacturing</option>
          <option value="Mining">Mining</option>
          <option value="Waste Management Services">Waste Management Services</option>
        </select>
      </div>
      <div className='fields2'>
      <input id='cEmail' type="text" placeholder='Company Email' name="companyEmail" onChange={(e)=>{setCompanyEmail(e.target.value)}}/>

        <input type="text" placeholder='Company phone (e.g 254 712345678)' name="companyPhone" onChange={(e)=>{setCompanyPhone(e.target.value)}}/>

      </div>
      <div className='fields2'>
        <select  id="" name="country" onChange={(e)=>{setCountry(e.target.value)}}>
          <option> Select Country</option>
          <option value="Kenya"> Kenya</option>
        </select>

        <input type="text" placeholder='State/Province/County' name="state" onChange={(e)=>{setState(e.target.value)}} />

      </div>
      <Button
        color="danger"
        onClick={nextStep}
        disabled={isLoading}
        style={{marginBottom:'3%', marginTop:'5%',width:'65%'}}
        
        
      >
        {isLoading ? 'Processing...' : 'Next'}
      </Button>
      <p>Already have an Accout? <a href="/auth/login">Log in</a></p>
      </div>  
      </div>
   </div>
      
    )}

    {step === 2 && (
      <div className='regForm'>
    <div className="stepsContainer">
    <img src="/assets/images/logo.png" className='logo' alt="" />
    <hr />
      <div className="cont">
        
    <h4>Personal Details</h4>
    <div className="fields">
    <input type="text" placeholder='Fullname' name="fullname"onChange={(e)=>{setFullname(e.target.value)}}/>
    </div>
      <div className='fields'>
        
        <input type="text" placeholder='Email' name="email" onChange={(e)=>{setEmail(e.target.value)}}/>

      </div>
     
      <div className='fields'>
        <input type="text" placeholder='Phone (e.g 254 712345678)' name="phone" onChange={(e)=>{setPhone(e.target.value)}}/>
      </div>
      <div className="fields">
      <select  id="" name="position" onChange={(e)=>{setPosition(e.target.value)}}>
          <option>Position</option>
          <option value="Director"> Director</option>
          <option value="Administrator"> Administrator</option>
          <option value="Secretary"> Secretary</option>
        </select>
      </div>
      <div className="fields">
       <input type="password" placeholder='Password' name="password" onChange={(e)=>{setPassword(e.target.value)}}/>
      </div>
      <div className="fields">
       <input type="password" placeholder='Confirm Password'  />
      </div>
    <div className="formButtons">
    <Button
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading2}
        style={{marginBottom:'1%', marginTop:'5%',width:'32%'}}
        
      >
       {isLoading ? 'Processing...' : 'Submit'}
      </Button>

      <Button
        color="danger"
        onClick={prevStep}
       
        style={{marginBottom:'1%', marginTop:'5%', marginLeft:'3%',width:'30%'}}
        
      >
        Previous
      </Button>

    </div>
     
      <p>Already have an Accout? <a href="https://board.datageboard.com/auth/login">Log in</a></p>
      </div>  
      </div>
      </div>
    )}

    
    
    </div>
    </div>
  </div>
);
  
}
