import {React, useState,useEffect} from 'react';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import Axios from 'axios';
import { io } from 'socket.io-client';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { auth } from '../../../firebase';
import './organisation.css'


export default function Organisationcard({email}) {
    const [file2, setFile2] = useState(null);

    const[company,setCompany] = useState('');
    const[industry,setIndustry] = useState('');
    const[companyEmail,setCompanyEmail] = useState('');
    const[companyPhone,setCompanyPhone] = useState('');
    const[country,setCountry] = useState('');
    const[state,setState] = useState('');
    const[stamp,setStamp] = useState('');

    const [file, setFile] = useState(null);

    const onChangeHandler = (event) => {
        setFile(event.target.files[0]);
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        try {
          await Axios.post('https://server.datageboard.com/api/upload-logo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          window.location.reload(false)
        } catch (error) {
          console.error('Error uploading file:', error);
          alert('Error uploading file');
        }
      };
    
      useEffect(() =>{
             
             Axios.get(`https://server.datageboard.com/api/getCompany?email=${email}`).then((res)=>{
              setCompany(res.data.company)
             } 
             )
      },[email])
    
      useEffect(() =>{
        Axios.get(`https://server.datageboard.com/api/getCompanyDetails?company=${company}`).then((res)=>{
          setIndustry(res.data.industry)
          setCompanyEmail(res.data.email)
          setCompanyPhone(res.data.phone)
          setCountry(res.data.country)
          setState(res.data.state)  
          setStamp(res.data.stamp) 
        })
       
      },[company])

      const handleChange = e => {
        const selectedFile = e.target.files[0];
        setFile2(e.target.files[0]);
  
        //Display preview
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const preview = document.getElementById('file-preview');
                preview.src = reader.result;
            };
            reader.readAsDataURL(selectedFile);
        }
      };

      const handleUpload = () => {

        if (!file2) {
            console.error('No file selected');
            return;
        }
        const { FileReader } = window; 

        const reader = new FileReader();
      
        reader.onload = () => {
          const arrayBuffer = reader.result;
          const { name } = file2;
          Axios.post('https://server.datageboard.com/api/postStamp',{
            name,company,content: arrayBuffer 

          }) 
         
        };
        reader.readAsArrayBuffer(file2);        
        toast.success('E-Stamp uploaded successfully')
    
  };
    
  return (
    <Card>
    <CardBody className='pb-0'>
      <CardTitle tag="h4">Organisation Overview</CardTitle>
    </CardBody>
    
    <div className='companyOverview'>
      <ToastContainer />
       
        
        <div className="companyTable">
        <div className="companyDetail">
                <h4>Registered Name</h4>
                <p>{company}</p>
            </div>
            <div className="companyDetail">
                <h4>Address</h4>
                <p>{country}, {state}</p>
            </div>
            <div className="companyDetail">
                <h4>Phone Number</h4>
                <p>{companyPhone}</p>
            </div>
            <div className="companyDetail">
                <h4>Email</h4>
                <p>{companyEmail}</p>
            </div>
            <div className="companyDetail">
                <h4>Industry</h4>
                <p>{industry}</p>
            </div>
            <div className="companyDetail">
                <h4>Organisation Age</h4>
                <p>Less than 1 year</p>
            </div>
            <div className="companyDetail">
                <h4>Date Format</h4>
                <p>MM//DD//YYYY</p>
            </div>
            <div className="companyDetail">
                <h4>Time Formant</h4>
                <p>24 HR</p>
            </div>
            <div className="companyDetail">
                <h4>Time Zone</h4>
                <p>(GMT+03:00) Nairobi - E. Africa Standard Time</p>
            </div>
            <div className="companyDetail">
                <h4>Upload Logo</h4>
                <form onSubmit={onSubmitHandler}>
                    <input type="file" name="file" onChange={onChangeHandler} />
                    <Button
                    color='primary'
                    type='submit'
                    >
                        Upload
                    </Button>
                    </form>
            </div>
            <div className="companyDetail">
                
                <div className="stampColumn">
                <h4>E-Stamp</h4>
               
                {stamp === '' &&  <img className='featureImg' style={{width:'20%'}} id="file-preview" src="" alt="Stamp" />}
                {stamp !== '' && <img src={`/assets/stamps/${stamp}`} style={{width:'20%'}}  alt='STAMP'/>}
               
                

                </div>
                <form onSubmit={handleUpload}>

                    <input type="file" name="file" onChange={handleChange} />
                    <Button
                    color='primary'
                    type='submit'
                    > 
                        Upload
                    </Button>
                    </form>
            </div>

            
        </div>
    
    </div>
  </Card>
  )
}
